import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faNewspaper, faImages } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './home.css';

const Home = () => {
    const navigate = useNavigate();

    return (
        <div className='home'>
            <TitleSeparator />
            <div className="container-home">
                <div className="box-home" onClick={() => { navigate("/38ababde-310d-41a6-8044-c3ee5490e0bd/home") }}>
                    <FontAwesomeIcon icon={faHome} className="icon-home" />
                    <span className="text-home">Inicio</span>
                </div>
                <div className="box-home" onClick={() => { navigate("/792e1233-85eb-498f-b94b-d2a95e5db2d4/gallery-admin") }}>
                    <FontAwesomeIcon icon={faNewspaper} className="icon-home" />
                    <span className="text-home">Noticias</span>
                </div>

                <div className="box-home" onClick={() => { navigate("/84eea01c-f572-4bac-a36c-8ec04f6ca641/news-admin") }}>
                    <FontAwesomeIcon icon={faImages} className="icon-home" />
                    <span className="text-home">Galería</span>
                </div>
            </div>
        </div>
    );
};


const TitleSeparator = () => {
    return (
        <div className="title-container">
            <h1 className="title-home">INICIO</h1>
            <div className="separator-home"></div>
        </div>
    );
};

export default Home;