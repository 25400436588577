
import axios from 'axios';
import store from './store';

const axiosClient = axios.create({
    baseURL: 'https://revista.liceomanuelelkin.edu.co/api',
});

axiosClient.interceptors.request.use(
    (config) => {
        store.dispatch({ type: 'SET_LOADING', payload: true }); 
        return config;
    },
    (error) => {
        store.dispatch({ type: 'SET_LOADING', payload: false });
        return Promise.reject(error);
    }
);

axiosClient.interceptors.response.use(
    (response) => {
        store.dispatch({ type: 'SET_LOADING', payload: false });
        return response;
    },
    (error) => {
        store.dispatch({ type: 'SET_LOADING', payload: false });
        return Promise.reject(error);
    }
);

export default axiosClient;
