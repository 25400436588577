import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Carousel, Pagination } from 'react-bootstrap';
import './Gallery.css';
import NoImagen from '../../assets/images/gallery/no-imagen.svg';
import { fetchGallery } from '../../redux/gallery/action.js';
import { useDispatch, useSelector } from 'react-redux';

function Gallery() {

    const dispatch = useDispatch(); 

    const data = useSelector(state => state.gallery.gallery );

    const chunkArray = (arr, size) => {
        const chunkedArr = [];
        for (let i = 0; i < arr?.length; i += size) {
            chunkedArr.push(arr?.slice(i, i + size));
        }
        return chunkedArr;
    };

    const [selectedId, setSelectedId] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const chunkedData = chunkArray(data, 3);

    useEffect(() => {
        dispatch(fetchGallery());
    }, [dispatch]);

    useEffect(() => {
        if (selectedId && data?.length > 0) {
            const selectedData = data?.find(item => item.id === selectedId);
            if (selectedData) {
                setSelectedImages(selectedData.images);
            }
        }
    }, [selectedId, data]);

    const handleCardClick = (id) => {
        setSelectedId(id);
    };

    const handleSelect = (selectedIndex) => {
        setSelectedId(chunkedData[selectedIndex][0].id);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderCarouselItems = () => {
        return chunkedData.map((group, index) => (
            <Carousel.Item key={index}>
                <div className="d-flex justify-content-around">
                    {group.map((card, idx) => (
                        <Card key={idx} style={{ width: '18rem', position: 'relative' }} onClick={() => handleCardClick(card.id)}>
                            <Card.Img variant="top" src={card.image} style={{height: "18rem", width: "18rem"}}/>
                            <Card.Body className='styleCards'>
                                <Card.Title className="text-center" style={{ color: 'white' }}>{card.event_name}</Card.Title>
                                <Card.Text style={{ color: 'white' }}>{card.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    ))}
                </div>
            </Carousel.Item>
        ));
    };

    const renderImageRows = () => {
        const itemsPerPage = 6;
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const currentImages = selectedImages.slice(startIndex, endIndex);

        return (
            <Row>
                {currentImages.map((img, index) => (
                    <Col xs={4} key={index} className="d-flex align-items-center">
                        <img src={img} alt={`Imagen ${index}`} style={{ width: '26.44rem', height: '16.56rem' }}/>
                    </Col>
                ))}
            </Row>
        );
    };

    const totalPages = Math.ceil(selectedImages.length / 6) ?? 0;
 
    return (
        <Container className='maxStyle mt-5'>
            <Row>
                <Col lg={1}></Col>
                <Col lg={10}>
                    <h2 className='titleGallery'>Galeria</h2>
                    <Row>
                        <Col lg={4} md={2}></Col>
                        <Col lg={4} md={8}>
                            <hr className="my-4" style={{backgroundColor: "#264A80", height: "0.1875rem"}}></hr>
                        </Col>
                        <Col lg={4} md={2}></Col>
                    </Row>
                        {data !== undefined && data?.length > 0 ? (
                            <>
                                <Carousel onSelect={handleSelect}>
                                    {renderCarouselItems()}
                                </Carousel>
                                <div className='cardGallery'>
                                    {renderImageRows()}
                                </div>
                                <div className='mt-5'>
                                    <Pagination className="justify-content-center mt-3 mb-5">
                                        {Array.from({ length: totalPages }).map((_, index) => (
                                            <Pagination.Item key={index} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                                                {index + 1}
                                            </Pagination.Item>
                                        ))}
                                    </Pagination>
                                </div>
                            </>
                        ) : (
                            <Container>
                                <Row>
                                    <Col lg={2}></Col>
                                    <Col lg={8} style={{textAlign: "-webkit-center"}}>
                                        <img src={NoImagen} alt="No imagen" style={{width: "50%"}}/>
                                        <p className='textNoImagen'>En este momento no cuentas con una galería disponible</p>
                                    </Col>
                                    <Col lg={2}></Col>
                                </Row>
                            </Container>
                        )}
                </Col>
            </Row>
        </Container>
    );
}

export default Gallery;
