import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Card, Col, Container, Nav, Row } from 'react-bootstrap';
import {
    dimensionsHalf,
    dimensionsPreschool,
    dimensionsPrimary,
    dimensionsSecundary,
    firstCarouselSubjects,
    getRandomSubject,
    images,
    metrics,
    MOBILE_MAX_WIDTH,
    subjectsGridG
} from './Index.ts';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLatestNews } from '../../redux/news/action.js';
import Button from '../../components/Button/Button.jsx';
import imgPrincipalLanding from '../../assets/images/landing/landing.png';
import imgMapsGoogle from '../../assets/images/landing/maps.png';

import 'swiper/css';
import 'swiper/css/pagination';
import './landing.css';

const Landing = () => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const latestNews = useSelector(state => state.news.latestNews);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_MAX_WIDTH);


    useEffect(() => {
        dispatch(fetchLatestNews());
    }, [dispatch])

    const handleTabClick = (index) => {
        setActiveTab(index);
    };


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <>
            <img src={imgPrincipalLanding} alt="principal-admision" className='imgPrincipal' />
            <Container className="metrics">
                {metrics.map(({ icon, value, title }, index) => (
                    <>
                        <div key={index} className="icon-metrics">
                            <img className='icon-svg' src={icon} alt={value} />
                            <p className='value-metrics'>{value}</p>
                            <p className='text-metrics'>{title}</p>
                        </div>
                        {index !== metrics.length - 1 && <div className="separator-metrics"></div>}
                    </>
                ))}

            </Container>
            <TitleLanding title="Asignaturas" />
            <div className="first-carrousel">
                <div style={{ float: 'left', width: isMobile ? '100%' : '20%' }}>
                    <Swiper
                        loop={true}
                        autoplay={{ delay: 3000 }}
                        direction={isMobile ? 'horizontal' : 'vertical'}
                        pagination={{
                            clickable: true,
                        }}
                        slidesPerView={isMobile ? 3 : 4}
                        spaceBetween={10}
                        modules={[Autoplay, Pagination, Navigation]}
                    >
                        {firstCarouselSubjects.map((subject, index) => (
                            <SwiperSlide key={index}>
                                <div className="subject-container-landing">
                                    <FontAwesomeIcon icon={subject.icon} size="3x" color="#264A80" className="icon-landing" />
                                    <p className="subject-name-landing">{subject.name}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <Swiper
                    loop={true}
                    autoplay={{ delay: 3000 }}
                    spaceBetween={30}
                    slidesPerView={1}
                    direction={isMobile ? 'horizontal' : 'vertical'}
                    pagination={{
                        clickable: true,

                    }}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                >
                    {images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <div className="swiper-slide" key={index}>
                                <img src={image} alt={`Slide ${index + 1}`} />
                                <div className="subject-overlay">{getRandomSubject()}</div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <TitleLanding title="NIVELES EDUCATIVOS POR ÁREAS ACADÉMICAS." />
            <MySubjectsGridGrados handleTabClick={handleTabClick} />
            <MySubjectsGrid activeTab={activeTab} handleTabClick={handleTabClick} />
            <div className='last-new'>
                {latestNews?.id && (
                    <>
                        <TitleLanding title="Ultima  publicación" />
                        <Card key={latestNews?.id} className="mb-3" style={{ borderRadius: "20px !important" }}>
                            <Row noGutters>
                                <Col md={3}>
                                    <Card.Img className="borderRadioGallery" src={latestNews?.image} alt={latestNews?.title} />
                                </Col>
                                <Col md={9}>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={8}>
                                                <Card.Title className='titleGallery'>{latestNews?.title}</Card.Title>
                                            </Col>
                                            <Col lg={4} className='text-end'>
                                                <small className="text-muted">{latestNews?.date}</small>
                                            </Col>
                                        </Row>
                                        <Card.Text className='textGallery mt-2'>    <div id="content-preview" dangerouslySetInnerHTML={{ __html: latestNews.description }}></div></Card.Text>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                        <Nav.Link href='/news'>
                            <Button />
                        </Nav.Link>
                    </>
                )
                }
            </div>
            < MapImage />
        </>
    );
}


const MySubjectsGridGrados = ({ handleTabClick }) => {
    return (
        <div className="subjects-grid-grados">
            {subjectsGridG.map((subject, index) => (
                <div onClick={() => handleTabClick(index)} className="grados-subject" key={index}>
                    <img src={subject.image} alt={subject.title} className="grados-image" />
                    <div className="subject-overlay grados-subject-text">{subject.title}</div>
                </div>
            ))}
        </div>
    );
};

function MySubjectsGrid({ activeTab, handleTabClick }) {

    return (
        <div className="container-landing-tabs">
            <div className="tabs-landing">
                {subjectsGridG.map((subject, index) => (
                    <div
                        key={index}
                        className={`tab-landing ${activeTab === index ? 'active' : ''}`}
                        onClick={() => handleTabClick(index)}
                    >
                        {subject.title}
                    </div>
                ))}
            </div>
            {activeTab === 0 && (
                <div className="content-container-landing">
                    {dimensionsPreschool.map((subject, index) => (
                        <div className="subject-container-landing-tabs" key={index}>
                            <FontAwesomeIcon icon={subject.icon} className="icon-landing-tabs" />
                            <p className="text-landing-tabs">{subject.name}</p>
                        </div>
                    ))}
                </div>
            )}
            {activeTab === 1 && (
                <div className="content-container-landing">
                    {dimensionsPrimary.map((subject, index) => (
                        <div className="subject-container-landing-tabs" key={index}>
                            <FontAwesomeIcon icon={subject.icon} className="icon-landing-tabs" />
                            <p className="text-landing-tabs">{subject.name}</p>
                        </div>
                    ))}
                </div>
            )}
            {activeTab === 2 && (
                <div className="content-container-landing">
                    {dimensionsSecundary.map((subject, index) => (
                        <div className="subject-container-landing-tabs" key={index}>
                            <FontAwesomeIcon icon={subject.icon} className="icon-landing-tabs" />
                            <p className="text-landing-tabs">{subject.name}</p>
                        </div>
                    ))}
                </div>
            )}
            {activeTab === 3 && (
                <div className="content-container-landing">
                    {dimensionsHalf.map((subject, index) => (
                        <div className="subject-container-landing-tabs" key={index}>
                            <FontAwesomeIcon icon={subject.icon} className="icon-landing-tabs" />
                            <p className="text-landing-tabs">{subject.name}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

const TitleLanding = ({ title }) => {
    return (
        <div className="title-landing">
            {title}
        </div>
    );
};

const MapImage = () => {
    const googleMapsUrl = 'https://www.google.com/maps/@4.6132016,-74.2059063,3a,75y,232.76h,115.49t/data=!3m6!1e1!3m4!1s3zS3goRiTwXsqgd_LEPKhQ!2e0!7i13312!8i6656?coh=205409&entry=ttu';

    return (
        <>
            <TitleLanding title="Nosotros" />
            <div className='img-center-landing-content'>
                <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
                    <img className='img-landing-maps' src={imgMapsGoogle} alt="Google Maps" />
                </a>
            </div>
        </>
    );
};

export default Landing;