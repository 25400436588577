
import {
    ADD_TO_NEWS, DELETE_FROM_NEWS,
    FETCH_LATEST_NEWS_FAILURE, FETCH_LATEST_NEWS_REQUEST,
    FETCH_LATEST_NEWS_SUCCESS, SET_NEWS, SET_NEWS_UNIQUE, UPDATE_NEWS_ITEM
} from "./type";
import axiosClient from '../../axiosClient';


export const fetchNews = () => {
    return async (dispatch) => {
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("USER_TOKEN")}`,
                    'Content-Type': 'multipart/form-data'
                }
            };
            const response = await axiosClient.get('/news', config);
            dispatch({
                type: SET_NEWS,
                payload: response.data.data
            });
        } catch (error) {
            dispatch({
                type: 'FETCH_NEWS_ERROR',
                payload: error.message
            });
        }
    };
};

export const fetchNewById = (id) => {
    return async (dispatch) => {
        try {
            const response = await axiosClient.get(`/news/${id}`);
            dispatch({
                type: SET_NEWS_UNIQUE,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: 'FETCH_NEW_BY_ID_ERROR',
                payload: error.message
            });
        }
    };
};

export const createNew = (formData, token) => {
    return async (dispatch) => {
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            };
            const response = axiosClient.post('/news', formData, config);
            dispatch({
                type: ADD_TO_NEWS,
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'CREATE_NEW_ERROR',
                payload: error.message
            });
        }
    };
};

export const updateNew = (id, formData) => {
    return async (dispatch) => {
        try {
            const response = await axiosClient.put(`/news/${id}`, formData);
            dispatch({
                type: UPDATE_NEWS_ITEM,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: 'UPDATE_NEW_ERROR',
                payload: error.message
            });
        }
    };
};

export const deleteNew = (id) => {
    return async (dispatch) => {
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("USER_TOKEN")}`,
                    'Content-Type': 'multipart/form-data'
                }
            };
            await axiosClient.delete(`/news/${id}`, config);
            dispatch({
                type: DELETE_FROM_NEWS,
                payload: id
            });
        } catch (error) {
            dispatch({
                type: 'DELETE_NEW_ERROR',
                payload: error.message
            });
        }
    };
};

export const fetchLatestNews = () => async (dispatch) => {
    dispatch({ type: FETCH_LATEST_NEWS_REQUEST });

    try {
        const { data } = await axiosClient.get('/news/latest-news');
        if (data.statusCode === 200 && data.data) {
            dispatch({
                type: FETCH_LATEST_NEWS_SUCCESS,
                payload: data.data,
            });
        } else {
            dispatch({
                type: FETCH_LATEST_NEWS_FAILURE,
                payload: 'No se pudo obtener la última noticia',
            });
        }
    } catch (error) {
        dispatch({
            type: FETCH_LATEST_NEWS_FAILURE,
            payload: error.message || 'Ocurrió un error al obtener la última noticia',
        });
    }
};