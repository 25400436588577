export const Path = [
    {
        title: 'Liceo',
        url: '/liceo',
    },
    {
        title: 'Admisiones',
        url: '/admisiones',
    },
    {
        title: 'Noticias',
        url: '/news',
    },
    {
        title: 'Galeria',
        url: '/gallery',
    }
];

export const PathAuth = [
    {
        title: 'Menú',
        url: '/38ababde-310d-41a6-8044-c3ee5490e0bd/home',
    },
    {
        title: 'Editar Galería',
        url: '/792e1233-85eb-498f-b94b-d2a95e5db2d4/gallery-admin',
    },
    {
        title: 'Editar Noticias',
        url: '/84eea01c-f572-4bac-a36c-8ec04f6ca641/news-admin',
    }
];
