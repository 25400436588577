import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Path, PathAuth } from './Index.ts';
import imgLogo from '../../assets/images/img-logo.svg';
import { logout } from '../../redux/auth/action.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import './Header.css';

function Header({ setIsloading, isLogin }) {
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.auth.isLoading);

    useEffect(() => {
        setIsloading(isLoading);
    }, [setIsloading, isLoading])

    return (
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" style={{ backgroundColor: "#fff !important" }}>
            {isLogin ? (
                <Container>
                    <Navbar.Brand as={Link} to="/" style={{ width: "35%" }}>
                        <img src={imgLogo} alt="Logo liceo" className="img-logo" style={{ width: "13%"}} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto" style={{ width: "100%", justifyContent: "center" }}>
                            {PathAuth.map(({ title, url }, index) => (
                                <Nav.Link
                                    as={Link}
                                    key={index}
                                    to={url}
                                    className="text nav-link"
                                    style={{ margin: 'auto' }}
                                >
                                    {title}
                                </Nav.Link>
                            ))}
                            <Nav.Link as={Link} to="/">
                                <Button className="text buttonPay" variant="primary" style={{ margin: '0 40px' }} onClick={async () => await dispatch(logout())}>
                                    <div className='logout'>
                                        <span >
                                            Cerrar Sesión
                                        </span>
                                        <FontAwesomeIcon icon={faRightToBracket} className="icon-bracket" />
                                    </div>
                                </Button>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            ) : (
                <Container>
                    <Navbar.Brand href={"/"} style={{ width: "35%" }}>
                        <img src={imgLogo} alt='Logo liceo' className='img-logo' style={{ width: "13%"}}/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto" style={{ width: "100%", justifyContent: "center" }}>
                            {Path.map(({ title, url }, index) => (
                                <Nav.Link
                                    key={index}
                                    href={url}
                                    className='text nav-link'
                                    style={{ margin: 'auto' }}
                                >
                                    {title}
                                </Nav.Link>
                            ))}
                            <Nav.Link href='/pago-en-linea'>
                                <Button className='text buttonPay' variant="primary" style={{ margin: '0 40px' }}>PAGA EN LÍNEA</Button>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            )}
        </Navbar>
    );
}

export default Header;
