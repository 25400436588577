import iconMission from '../../assets/images/mission-vision/mission.svg';
import iconVision from '../../assets/images/mission-vision/vision.svg';
import iconResponsibilities from '../../assets/images/mission-vision/responsibilities.svg';
import iconRespect from '../../assets/images/mission-vision/respect.svg';
import iconProactivity from '../../assets/images/mission-vision/proactivity.svg';
import iconTolerance from '../../assets/images/mission-vision/tolerance.svg';
import iconHonesty from '../../assets/images/mission-vision/equity.svg';

export const moralValues = [
    {
        icon: iconResponsibilities,
        className: "colorYellowValues",
        title: 'Responsabilidad',
        description: 'La capacidad para asumir y respaldar con seriedad y entereza todos los actos de la existencia. La responsabilidad se manifiesta en el ejercicio continuo y positivo de los compromisos individuales, sociales, cívicos, escolares, familiares y laborales, contraídos, y la valoración permanente de los resultados que de ellos puedan derivarse. Dentro de este valor es indispensable la corresponsabilidad.',
    },
    {
        icon: iconRespect,
        className: "coloBlueValues",
        title: 'Respeto',
        description: 'Implica el aprecio por la dignidad humana, el acatamiento a la norma. Comprender que la cortesía, el buen trato, los buenos modales, la amabilidad, la gratitud, la tolerancia y la puntualidad son formas de mostrar respeto por los demás. Expresar las ideas propias y respetar las de los demás.',
    },
    {
        icon: iconHonesty,
        className: "colorYellowValues",
        title: 'Honestidad',
        description: 'La persona honesta vive lo que predica y expresa sus pensamientos sin irrespetar a nadie, dice oportunamente la verdad, es justa al asignar a cada cual lo que corresponde. Del honesto se puede decir: “He aquí un hombre en quien no hay doblez”. Cita bíblica.',
    },
    {
        icon: iconProactivity,
        className: "coloBlueValues",
        title: 'Proactividad',
        description: 'Que las conductas no sean consecuencia de las circunstancias, los sentimientos y las personas, sino fruto de la planificación. Cumplir con los compromisos adquiridos, asumir las consecuencias de las decisiones, crear nuevas y mejores formas para efectuar el trabajo; hacer bien desde el principio y sentirse responsable de la institución.',
    },
    {
        icon: iconTolerance,
        className: "colorYellowValues",
        title: 'Tolerancia y participación',
        description: 'Para construir una sociedad más justa y equilibrada, de respeto por el prójimo, consideraciones por los intereses ajenos, aprovechamiento al máximo de las facultades intelectuales; promoción de la vida armónica y productiva en comunidad; y la lucha constante por una sociedad más equitativa.',
    }
];

export const missionAndVision = [
    {
        icon: iconMission,
        className: 'containerMission p-5',
        title: 'Misión',
        description: 'La misión del Liceo Manuel Elkin Patarroyo está orientada a alcanzar una educación de formación integral, por medio del modelo pedagógico de investigación-acción, involucrando el trabajo por competencias, para desarrollar todas las dimensiones del ser humano, profundizando en la comprensión de lectura y los procesos de pensamiento lógico, promoviendo acciones de convivencia pacífica con cultura de solución de conflictos, que permitan al estudiante fortalecer sus habilidades individuales y trascender en la sociedad.',
    },
    {
        icon: iconVision,
        className: 'containerVision p-5',
        title: 'Visión',
        description: 'Para el año 2022, el Liceo Manuel Elkin Patarroyo ofrecerá un servicio de calidad en los niveles de Educación Preescolar, Básica y Media académica con énfasis en el idioma extranjero Inglés, impactando a la comunidad del Barrio Getsemaní de la localidad de Bosa y sus alrededores de la ciudad capital de Bogotá.',
    }
];