import React from 'react';
import './Footer.css';
import iconFacebook from '../../assets/images/footer/facebook.png';
import iconYouTube from '../../assets/images/footer/youTube.png';
import { Col, Container, Row } from 'react-bootstrap';
import { InformationServices } from './Index.ts';

function Footer() {
    return (
        <Container className='backgroundColor'>
            <Row>
                <Col lg={6}>
                    <p className='mt-5 title change-color-text-footer'>Servicio al cliente</p>
                    <ul className="list-group">
                   {Array.isArray(InformationServices) && InformationServices?.map(({ text, icon }, index) => (
                       <li className="list-group-item backgroundColor liColor" key={index}>
                           <img src={icon} alt={text} className='icon'/>
                           <span className='change-color-text-footer'>{text}</span>
                       </li>
                   ))}
                   <p className='title mt-3 change-color-text-footer' style={{fontWeight: "400"}}>© 2024 Liceo Manuel Elkin Patarroyo </p>
                 </ul>
                </Col>
                <Col lg={6}>
                    <div className="d-flex flex-column justify-content-between h-100">
                        <div>
                            <p className='title mt-5 change-color-text-footer'>Contáctenos</p>
                            <a href="https://www.facebook.com/share/emLwFhk6YcGMNCQz/?mibextid=kFxxJD">
                                <img src={iconFacebook} alt='Facebook' className=' mb-4 mt-2 liColor'/>
                            </a>
                            <img src={iconYouTube} alt='YouTube' className=' mb-4 mt-2 iconYouTube'/>
                        </div>
                        <div>
                            <p className=' change-color-text-footer resolution'>Resolución No 07 – 0016 (19 de julio de 2022) por la cual se modifica y aclara la resolución 07-005 de 30/08/2021 que modifico las resoluciones 962 de 16/03//2006;  3877 de 20 de septiembre de 2.007; 07-0271 de 04/10/2010; y 07-0498 de 09/03/2012 y por cambio de titular de la licencia del establecimiento educativo privado LICEO MANUEL ELKIN PATARROYO con código DANE 311001097658.  31100197653
                            Kra 81ª Nº 82ª- 18 sur B. Getsemani . loc. Bosa  TEL: 4.49.29.56 ; EMAIL: ELVIRISTO@YAHOO.COM  NIT: 901461773-0</p>
                        </div>
                        <div className="title mt-auto"  style={{fontWeight: "400"}}>
                            <p className='change-color-text-footer'>Política de privacidad | Política de tratamiento de datos</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;
