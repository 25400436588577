import iconGraduates from '../../assets/images/landing/icons/graduates.svg';
import iconStudents from '../../assets/images/landing/icons/students.svg';
import iconYears from '../../assets/images/landing/icons/years.svg';
import iconStart from '../../assets/images/landing/icons/top.svg';

import half from '../../assets/images/landing/degrees/Media.jpg';
import preschool from '../../assets/images/landing/degrees/Prescolar.jpg';
import primary from '../../assets/images/landing/degrees/Primaria.jpg';
import secundary from '../../assets/images/landing/degrees/Secundaria.jpg';
import { faAtom, faGlobe, faCalculator, faBook, faLanguage, faCross, faLaptop, faHandsHelping, faPalette, faPencilRuler, faRunning } from '@fortawesome/free-solid-svg-icons';
import { faUsers, faBrain, faComments } from '@fortawesome/free-solid-svg-icons'; // Importa los iconos necesarios de FontAwesome
import {
    faFlask,
    faMusic,
    faDraftingCompass,
    faDesktop
} from '@fortawesome/free-solid-svg-icons';

import {
    faMoneyBillWave,
    faMicroscope,
    faHatWizard,
} from '@fortawesome/free-solid-svg-icons';

export const metrics = [
    {
        icon: iconYears,
        className: 'containerMission',
        title: 'Años de experiencia',
        value: '25',
    },
    {
        icon: iconGraduates,
        className: 'containerMission',
        title: 'Graduados',
        value: '251',
    },
    {
        icon: iconStudents,
        className: 'containerMission',
        title: 'Estudiantes',
        value: '825',
    },
    {
        icon: iconStart,
        className: 'containerMission',
        value: 'Top',
        title: 'ICFES BOSA',
    },
];

export const subjectsGridG = [
    {
        image: preschool,
        title: 'Nivel Prescolar',
    },
    {
        image: primary,
        title: 'Nivel Básica Primaria',
    },
    {
        image: secundary,
        title: 'Nivel Básica Secundaria',
    },
    {
        image: half,
        title: 'Nivel Educación Media',
    },
];


declare const require: {
    context(
        directory: string,
        useSubdirectories: boolean,
        regExp: RegExp
    ): {
        keys(): string[];
        <T>(id: string): T;
    };
};

const subjects = [
    "Dimensión Socio-Afectiva (Convivencia)",
    "Dimensión Corporal (Educación Física)",
    "Dimensión Cognitiva (Matemáticas, Ciencias, Sociales, Sistemas)",
    "Dimensión Comunicativa ( Escritura, Ingles)",
    "Dimensión Estética (Artes y Danzas)",
    "Cognitivas y Convivenciales",
    "Ciencias Naturales",
    "Ciencias Sociales",
    "Matemáticas",
    "Lenguaje",
    "Educación Religiosa y Moral",
    "Idioma Extranjero (Ingles)",
    "Tecnología e Informática",
    "Educación Ética y Valores Humanos",
    "Educación Física, Recreación y Deportes",
    "Danzas",
    "Educación Estética (Dibujo)",
    "Dibujo Técnico"
];

export const firstCarouselSubjects = [
    { name: 'Ciencias Naturales', icon: faAtom },
    { name: 'Ciencias Sociales', icon: faGlobe },
    { name: 'Matemáticas', icon: faCalculator },
    { name: 'Lenguaje', icon: faBook },
    { name: 'Idioma Extranjero (Ingles)', icon: faLanguage },
    { name: 'Educación Religiosa y Moral', icon: faCross },
    { name: 'Tecnología e Informática', icon: faLaptop },
    { name: 'Educación Ética y Valores Humanos', icon: faHandsHelping },
    { name: 'Danzas', icon: faPalette },
    { name: 'Dibujo Técnico', icon: faPencilRuler },
    { name: 'Educación Física, Recreación y Deportes', icon: faRunning }
];

export const dimensionsPreschool = [
    { name: 'Dimensión Socio-Afectiva (Convivencia)', icon: faUsers },
    { name: 'Dimensión Corporal (Educación Física)', icon: faRunning },
    { name: 'Dimensión Cognitiva (Matemáticas, Ciencias, Sociales, Sistemas)', icon: faBrain },
    { name: 'Dimensión Comunicativa (Escritura, Ingles)', icon: faComments },
    { name: 'Dimensión Estética (Artes y Danzas)', icon: faPalette },
    { name: 'Cognitivas y Convivenciales', icon: faHandsHelping }
];

export const dimensionsPrimary = [
    { name: 'Ciencias Naturales', icon: faAtom },
    { name: 'Ciencias Sociales', icon: faGlobe },
    { name: 'Matemáticas', icon: faCalculator },
    { name: 'Lenguaje', icon: faBook },
    { name: 'Educación Religiosa y Moral', icon: faCross },
    { name: 'Idioma Extranjero (Ingles)', icon: faLanguage },
    { name: 'Tecnología e Informática', icon: faLaptop },
    { name: 'Educación Ética y Valores Humanos', icon: faHandsHelping },
    { name: 'Educación Física, Recreación y Deportes', icon: faRunning },
    { name: 'Danzas', icon: faPalette },
    { name: 'Educación Estética (Dibujo)', icon: faPencilRuler },
    { name: 'Cognitivas y Convivenciales', icon: faUsers }
];

export const dimensionsSecundary = [
    { name: 'Ciencias Naturales', icon: faFlask },
    { name: 'Ciencias Sociales', icon: faGlobe },
    { name: 'Matemáticas', icon: faCalculator },
    { name: 'Lenguaje', icon: faBook },
    { name: 'Idioma Extranjero (Inglés)', icon: faLanguage },
    { name: 'Educación Religiosa y Moral', icon: faCross },
    { name: 'Tecnología e Informática', icon: faDesktop },
    { name: 'Educación Ética y Valores Humanos', icon: faHandsHelping },
    { name: 'Danzas', icon: faMusic },
    { name: 'Dibujo Técnico', icon: faDraftingCompass },
    { name: 'Educación Física, Recreación y Deportes', icon: faRunning },
    { name: 'Cognitivas y Convivenciales', icon: faUsers }
];

export const dimensionsHalf = [
    { name: 'Física', icon: faAtom },
    { name: 'Química', icon: faFlask },
    { name: 'Matemáticas', icon: faCalculator },
    { name: 'Lenguaje', icon: faBook },
    { name: 'Idioma Extranjero (Inglés)', icon: faLanguage },
    { name: 'Ciencias Sociales', icon: faGlobe },
    { name: 'Ciencias Políticas Económicas', icon: faMoneyBillWave },
    { name: 'Biología', icon: faMicroscope },
    { name: 'Educación Ética y Valores Humanos', icon: faHandsHelping },
    { name: 'Educación Religiosa y Moral', icon: faCross },
    { name: 'Filosofía', icon: faHatWizard },
    { name: 'Educación física, Recreación y Deportes', icon: faRunning },
    { name: 'Dibujo Técnico', icon: faDraftingCompass },
    { name: 'Danzas', icon: faMusic },
    { name: 'Tecnología e Informática', icon: faDesktop },
    { name: 'Cognitivas y Convivenciales', icon: faUsers }
];

const imagesContext = require.context('../../assets/images/landing/swiper1', false, /\.(png|jpe?g|svg)$/);
const importAll = (context: ReturnType<typeof require.context>) => context.keys().map(context);
export const images = importAll(imagesContext);
export const getRandomSubject = () => subjects[Math.floor(Math.random() * subjects.length)];

export const MOBILE_MAX_WIDTH = 768;
