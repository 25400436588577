import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './PayOnLine.css';
import YouTube from 'react-youtube';

function PayOnLine () {
    const opts = {
        height: '360',
        width: '100%',
        playerVars: {
          autoplay: 0,
        },
    };
    return (
        <Container className='maxStyle mt-5'>
            <Row>
                <Col lg={1}></Col>
                <Col lg={10}>
                    <h2 className='titlePayOnLine'>PAGO EN LÍNEA.</h2>
                    <hr className="my-4" style={{backgroundColor: "#2A2F9F", height: "0.1875rem"}}></hr>
                    <p className='paragraphPay'>Ponemos a disposición de nuestros usuarios y los padres de familia la plataforma mipagoamigo que les permite realizar pagos en línea de manera segura para cubrir servicios como matrícula, mensualidades, comedor, transporte, certificados escolares y costos de graduación.</p>

                    <h2 className='titlePayOnLine' style={{fontSize:"22px"}}>¿CÓMO REALIZAR SUS PAGOS EN LÍNEA?</h2>
                    <hr className="my-4" style={{backgroundColor: "#2A2F9F", height: "0.1875rem", width:"57%"}}></hr>
                    <p className='paragraphPay mt-3'>A continuación encontrará el video con el paso a paso para realizar sus pagos.</p>
                    <Row>
                        <Col lg={1}></Col>
                        <Col lg={10} className='text-center mt-5'>
                            <YouTube videoId="PyreyYONRL" opts={opts} />
                        <div className='mt-5 mb-5'>
                            <a href="https://www.mipagoamigo.com/MPA_WebSite/ServicePayments/StartPayment?id=6284&searchedCategoryId=&searchedAgreementName=liceo+manuel+elkin+patarroyo" target="_blank" rel="noopener noreferrer" className='text buttonPay mt-4 mb-5' style={{ margin: '0 40px', textDecoration: 'none', color: 'white', backgroundColor: 'blue', padding: '10px 20px', borderRadius: '5px' }}>
                                PAGA EN LÍNEA
                            </a>
                        </div>
                        </Col>
                        <Col lg={1}></Col>
                    </Row>
                </Col>
                <Col lg={1}></Col>
            </Row>
        </Container>
    );
}

export default PayOnLine;
