import { ADD_TO_GALLERY, DELETE_FROM_GALLERY, SET_GALLERY, SET_GALLERY_UNIQUE, UPDATE_GALLERY_ITEM } from './type';

const initialState = {
    gallery: [],
    uniqueGallery: {},
};

export default function galleryReducer(state = initialState, action) {
    switch (action.type) {
        case SET_GALLERY:
            return {
                ...state,
                gallery: action.payload
            };
        case SET_GALLERY_UNIQUE:
            return {
                ...state,
                uniqueGallery: action.uniqueGallery
            };
        case ADD_TO_GALLERY:
            return {
                ...state,
                gallery: [...state.gallery, action.payload]
            };
        case UPDATE_GALLERY_ITEM:
            return {
                ...state,
                gallery: state.gallery.map(item =>
                    item.id === action.payload.id ? action.payload : item
                )
            };
        case DELETE_FROM_GALLERY:
            return {
                ...state,
                gallery: state.gallery.filter(item => item.id !== action.payload)
            };
        case 'SET_GALLERY':
            return {
                ...state,
                gallery: action.payload
            };
        default:
            return state;
    }
}