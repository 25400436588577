import React from 'react';
import './Button.css';

const Button = () => {
    return (
        <div className="button-container">
            <div className="button-text">Ver más</div>
        </div>
    );
};
export default Button;