import { ADD_TO_GALLERY, DELETE_FROM_GALLERY, SET_GALLERY, SET_GALLERY_UNIQUE, UPDATE_GALLERY_ITEM } from './type';
import axiosClient from '../../axiosClient';

export const fetchGalleries = () => {
    return async (dispatch) => {
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("USER_TOKEN")}`,
                    'Content-Type': 'multipart/form-data'
                }
            };

            const response = await axiosClient.get('/gallery', config);
            dispatch({
                type: SET_GALLERY,
                payload: response.data.data
            });
        } catch (error) {
            dispatch({
                type: 'FETCH_GALLERIES_ERROR',
                payload: error.message
            });
        }
    };
};

export const fetchGalleryById = (id) => {
    return async (dispatch) => {
        try {
            const response = await axiosClient.get(`/gallery/${id}`);
            dispatch({
                type: SET_GALLERY_UNIQUE,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: 'FETCH_GALLERY_BY_ID_ERROR',
                payload: error.message
            });
        }
    };
};

export const createGallery = (formData, token) => {
    return async (dispatch) => {
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            };

            const mainImageData = {
                name: `Main_${Math.random().toString(36).substring(7)}`,
                event_name: formData.title,
                is_primary_image: 1,
                image: formData.mainImage
            };

            const secondaryImagesData = formData.secondaryImages.map(image => ({
                name: `Secondary_${Math.random().toString(36).substring(7)}`,
                event_name: formData.title,
                is_primary_image: 0,
                image
            }));

            const allImageData = [mainImageData, ...secondaryImagesData];

            const promises = allImageData.map(imageData => axiosClient.post('/gallery', imageData, config));
            await Promise.all(promises);

            dispatch({
                type: ADD_TO_GALLERY,
                payload: allImageData
            });
        } catch (error) {
            dispatch({
                type: 'CREATE_GALLERY_ERROR',
                payload: error.message
            });
        }
    };
};

export const updateGallery = (id, formData) => {
    return async (dispatch) => {
        try {
            const response = await axiosClient.put(`/gallery/${id}`, formData);
            dispatch({
                type: UPDATE_GALLERY_ITEM,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: 'UPDATE_GALLERY_ERROR',
                payload: error.message
            });
        }
    };
};

export const deleteGallery = (id) => {
    return async (dispatch) => {
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("USER_TOKEN")}`,
                    'Content-Type': 'multipart/form-data'
                }
            };
            await axiosClient.delete(`/gallery/${id}`, config);
            dispatch({
                type: DELETE_FROM_GALLERY,
                payload: id
            });
        } catch (error) {
            dispatch({
                type: 'DELETE_GALLERY_ERROR',
                payload: error.message
            });
        }
    };
};

export const fetchGallery = () => {
    return async (dispatch) => {
        try {
            const response = await axiosClient.get('/gallery/get-gallery');
            dispatch({
                type: SET_GALLERY,
                payload: response.data.data
            });
        } catch (error) {
            console.error('Error fetching gallery:', error);
        }
    };
};