
import { ADD_TO_NEWS, DELETE_FROM_NEWS, FETCH_LATEST_NEWS_FAILURE, FETCH_LATEST_NEWS_REQUEST, FETCH_LATEST_NEWS_SUCCESS, SET_NEWS, SET_NEWS_UNIQUE, UPDATE_NEWS_ITEM } from "./type";

const initialState = {
    news: [],
    latestNews: null,
    loading: false,
    error: null,
};

const newsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NEWS:
            return {
                ...state,
                news: action.payload
            };
        case SET_NEWS_UNIQUE:
            return {
                ...state,
                uniquenews: action.uniquenews
            };
        case ADD_TO_NEWS:
            return {
                ...state,
                news: [...state.news, action.payload]
            };
        case UPDATE_NEWS_ITEM:
            return {
                ...state,
                news: state.news.map(item =>
                    item.id === action.payload.id ? action.payload : item
                )
            };
        case DELETE_FROM_NEWS:
            return {
                ...state,
                news: state.news.filter(item => item.id !== action.payload)
            };
        case FETCH_LATEST_NEWS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_LATEST_NEWS_SUCCESS:
            return {
                ...state,
                latestNews: action.payload,
                loading: false,
                error: null,
            };

        case FETCH_LATEST_NEWS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default newsReducer;
