import React, { useState } from 'react';
import { Card, Col, Pagination, Row } from 'react-bootstrap';
import './Paginador.css';

function Paginator({ itemsPerPage, data}) {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(data.length / itemsPerPage);
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    const paginatedData = data.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  
    return (
      <div>
        {/* Aquí renderiza los elementos que deseas mostrar por página */}
        {paginatedData.map((item, index) => (
            <Card key={index} className="mb-3" style={{borderRadius: "20px !important"}}>
                <Row noGutters>
                    <Col md={3}>
                        <Card.Img className="borderRadioGallery" src={item.image} alt={item.title} />
                    </Col>
                    <Col md={9}>
                    <Card.Body>
                        <Row>
                            <Col lg={8}>
                                <Card.Title className='titleGallery'>{item.title}</Card.Title>
                            </Col>
                            <Col lg={4} className='text-end'>
                                <small className="text-muted">{item.date}</small>
                            </Col>
                        </Row>
                        <Card.Text className='textGallery mt-2'><div dangerouslySetInnerHTML={{ __html: item.description }} /></Card.Text>
                    </Card.Body>
                    </Col>
                </Row>
            </Card>
        ))}
  
        {/* Renderiza el paginador */}
        <div style={{ display: 'flex', justifyContent: 'center' }} className='mb-5'>
        <Pagination>
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
            {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item
                    key={index}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                >
                    {index + 1}
                </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
        </Pagination>
    </div>
      </div>
    );
}

export default Paginator;
  