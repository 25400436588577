import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createGallery, deleteGallery, fetchGalleries } from '../../redux/gallery/action';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import './GalleryForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GalleryForm = () => {
  const dispatch = useDispatch();
  
  const mainImageInputRef = useRef(null);
  const secondaryImagesInputRef = useRef(null);
  const gallery = useSelector(state => state.gallery.gallery || []);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const eventNames = [...new Set(gallery.map(item => item.event_name))];
  const [titleType, setTitleType] = useState('text');
  const [formData, setFormData] = useState({
    title: '',
    mainImage: null,
    secondaryImages: []
  });

  useEffect(() => {
    dispatch(fetchGalleries());
  }, [dispatch]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      title: e.target.value
    });
  };

  const handleMainImageChange = (e) => {
    setFormData({
      ...formData,
      mainImage: e.target.files[0]
    });
  };

  const handleSecondaryImagesChange = (e) => {
    setFormData({
      ...formData,
      secondaryImages: Array.from(e.target.files)
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('USER_TOKEN');
    dispatch(createGallery(formData, token));
    dispatch(fetchGalleries());
    setFormData({
      title: '',
      mainImage: null,
      secondaryImages: []
    });

    if (mainImageInputRef.current) {
      mainImageInputRef.current.value = '';
    }
    if (secondaryImagesInputRef.current) {
      secondaryImagesInputRef.current.value = '';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('es-ES', options);
  };

  const imageBodyTemplate = (rowData) => {
    return <img src={rowData.image} alt={rowData.name} style={{ width: '100px' }} />;
  };

  const deleteItem = (item) => {
    dispatch(deleteGallery(item?.id));
    dispatch(fetchGalleries());
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <button className="p-button p-component p-button-rounded p-button-danger" onClick={() => deleteItem(rowData)}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    );
  };
  const filteredGallery = selectedEvent ? gallery.filter(item => item.event_name === selectedEvent) : gallery;


  const toggleTitleType = () => {
    setTitleType(prevType => (prevType === 'text' ? 'select' : 'text'));
  };

  const renderTitleField = () => {
    if (titleType === 'text') {
      return (
        <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} required />
      );
    } else if (titleType === 'select') {
      return (
        <Dropdown
          id="title"
          name="title"
          value={formData.title}
          options={eventNames.map(name => ({ label: name, value: name }))}
          onChange={handleChange}
          placeholder="Seleccionar evento"
          required
          style={{ width: '100%' }}
        />);
    }
  };

  const handleCheckboxChange = () => {
    setFormData({
      ...formData,
      title: ''
    });
    toggleTitleType();
  };
  return (
    <div style={{ 'marginTop': '100px' }}>
      <div className="title-container-gallery">
        <h1 className="title-gallery-form ">Galeria</h1>
        <div className="separator--gallery-form "></div>
      </div>
      <div className="container">
        <div className="container-gallery-admin">
          <h2 className="title-gallery-form">Agregar Foto</h2>
          <form className="gallery-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="title">Título:</label>
              {renderTitleField()}
            </div>
            <div>
              <label>
                ¿Día ya agregado?
                <input
                  type="checkbox"
                  id="is-primary-image"
                  name="isPrimaryImage"
                  checked={formData.isPrimaryImage}
                  onChange={() => handleCheckboxChange()}
                  className="round-checkbox"
                />
              </label>
            </div>
            {titleType === 'text' && (
              <div className="form-group">
                <label htmlFor="main-image">Imagen Principal:</label>
                <input type="file" id="main-image" ref={mainImageInputRef} name="mainImage" onChange={handleMainImageChange} accept="image/*" required />
              </div>
            )}
            <div className="form-group">
              <label htmlFor="secondary-images">Imágenes Secundarias:</label>
              <input type="file" id="secondary-images" ref={secondaryImagesInputRef} name="secondaryImages" onChange={handleSecondaryImagesChange} accept="image/*" multiple />
            </div>
            <button type="submit">Agregar</button>
          </form>
        </div>
        <div className="container-gallery-admin-table">
          <label className='text-table-gallery' >Filtrar Nombre del Evento</label>
          <div className='filter-table-gallery'>
            <Dropdown
              value={selectedEvent}
              options={eventNames.map(name => ({ label: name, value: name }))}
              onChange={(e) => setSelectedEvent(e.value)}
              placeholder="Seleccionar Evento"
              width={'100%'}
            />
          </div>
          <DataTable value={filteredGallery} paginator rows={5} rowsPerPageOptions={[5, 10, 20]}>
            <Column field="event_name" header="Nombre del Evento" headerClassName="blue-header" />
            <Column field="image" header="Imagen" body={imageBodyTemplate} headerClassName="blue-header" />
            <Column field="is_primary_image" header="Carrusel Principal" headerClassName="blue-header" />
            <Column field="created_at" header="Fecha de creación" body={(rowData) => formatDate(rowData.created_at)} headerClassName="blue-header" />
            <Column header="Acciones" headerClassName="blue-header" body={actionBodyTemplate} />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default GalleryForm;
