// App.js

import React, { useState } from 'react';
import { Provider } from 'react-redux';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Loader from './components/Load/Load';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import MissionVision from './pages/mission-vision/MissionVision';
import PayOnLine from './pages/pay-on-line/PayOnLine';
import Admissions from './pages/admissions/Admissions';
import Landing from './pages/landing/landing';
import store from './store';
import News from './pages/news/News';
import Gallery from './pages/gallery/Gallery';

import Login from './pages/login/login';
import ProtectedRoute from './components/PrivateRoutes/PrivateRoutes';
import Home from './pages/home/home';
import GalleryForm from './pages/gallery-admin/GalleryForm';
import NewsForm from './pages/gallery-news/NewsForm';
import { PrimeReactProvider } from 'primereact/api';

import "primereact/resources/themes/lara-light-cyan/theme.css";
import './App.css'

const App = () => {
  const isLogin = store.getState().auth.isLoggedIn;
  const [isLoading, setIsloading] = useState(false);

  return (
    <PrimeReactProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Header setIsloading={setIsloading} isLogin={isLogin} />
          {isLoading && <Loader />}
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/liceo" element={<MissionVision />} />
            <Route path="/pago-en-linea" element={<PayOnLine />} />
            <Route path="/admisiones" element={<Admissions />} />
            <Route path="/news" element={<News />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/802f44a8-1b1d-45e5-b179-ba57a6eeab2f/login" element={<Login />} />
            <Route
              path='/38ababde-310d-41a6-8044-c3ee5490e0bd/home'
              element={
                <ProtectedRoute isAllowed={isLogin} redirectTo='/'>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path='/792e1233-85eb-498f-b94b-d2a95e5db2d4/gallery-admin'
              element={
                <ProtectedRoute isAllowed={isLogin} redirectTo='/'>
                  <GalleryForm />
                </ProtectedRoute>
              }
            />
            <Route
              path='/84eea01c-f572-4bac-a36c-8ec04f6ca641/news-admin'
              element={
                <ProtectedRoute isAllowed={isLogin} redirectTo='/'>
                  <NewsForm />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </Provider>
    </PrimeReactProvider>
  );
}

export default App;
