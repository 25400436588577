import React, { useEffect } from 'react';
import './News.css';
import Carousel from 'react-bootstrap/Carousel';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Paginator from '../../components/Paginador/Paginador';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNews } from '../../redux/news/action';
import NoImagen from '../../assets/images/gallery/no-imagen.svg';

function News() {
    const dispatch = useDispatch();
    const data = useSelector(state => state.news.news);

    useEffect(() => {
        dispatch(fetchNews());
    }, [dispatch]);

    return (
        <Container className='maxStyle mt-5'>
            <Row>
                <Col lg={1}></Col>
                <Col lg={10}>
                    <h2 className='titleNews'>Noticias</h2>
                    <hr className="my-4" style={{ backgroundColor: "#264A80", height: "0.1875rem" }}></hr>
                    {data['latestNews'] !== undefined && data['latestNews']?.length > 0 ? (
                        <>
                            <Carousel>
                                {data['latestNews']?.map((item, index) => (
                                    <Carousel.Item className='slide'>
                                        <Row>
                                            <Col lg={1}></Col>
                                            <Col lg={10}>
                                                <Card key={index} className="mb-3">
                                                    <Row>
                                                        <Col md={6}>
                                                            <Card.Body>
                                                                <Row>
                                                                    <Col lg={8}>
                                                                        <Card.Title className='titleCarouseNews'>{item.title}</Card.Title>
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        <small className="dateCarouseNews">{item.date}</small>
                                                                    </Col>
                                                                </Row>
                                                                <Card.Text className='descriptionCarouseNews mt-5 mb-3'><div dangerouslySetInnerHTML={{ __html: item.description }} /></Card.Text>
                                                            </Card.Body>
                                                        </Col>
                                                        <Col md={4}>
                                                            <Card.Img src={item.image} alt={item.title} />
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col lg={1}></Col>
                                        </Row>
                                    </Carousel.Item>
                                ))}
                            </Carousel>

                            <h2 className='subtitleNews mt-5'>Noticias / Eventos</h2>
                            <hr className="my-4" style={{ backgroundColor: "#264A80", height: "0.1875rem", width: "35%" }}></hr>
                            <Paginator data={data['otherNews']} itemsPerPage={4} />
                        </>
                    ) : (
                        <Container>
                            <Row>
                                <Col lg={2}></Col>
                                <Col lg={8} style={{ textAlign: "-webkit-center" }}>
                                    <img src={NoImagen} alt="No imagen" style={{ width: "50%" }} />
                                    <p className='textNoImagen'>En este momento no cuentas con noticias</p>
                                </Col>
                                <Col lg={2}></Col>
                            </Row>
                        </Container>
                    )}
                </Col>
                <Col lg={1}></Col>
            </Row>
        </Container>
    );
}

export default News;