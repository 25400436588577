import axiosClient from '../../axiosClient';

export const loginAction = (email, password) => async (dispatch) => {
  try {
    const response = await axiosClient.post('/auth/login', {
      email,
      password
    });
    localStorage.setItem('USER_TOKEN', response.data.data.access_token);
    localStorage.setItem('USER_DATA', JSON.stringify(response.data.data));
    dispatch({
      type: "LOGIN",
      payload: {
        user: response.data.data,
        token: response.data.data.token
      }
    });

    return response;
  } catch (error) {
    console.error('Error al iniciar sesión:', error);
  }
};

export const logout = () => async (dispatch) => {
  try {
    await axiosClient.post('/auth/logout', null);

  } catch (error) {
    
  }
  dispatch({ type: "LOGOUT" });
  localStorage.removeItem('USER_TOKEN');
  localStorage.removeItem('USER_DATA');
};
