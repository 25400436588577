import React from 'react';
import './Admissions.css';
import imgPrincipalAdmissions from '../../assets/images/admissions/admissions.png';
import { Col, Container, Row } from 'react-bootstrap';

function Admissions() {
    const images = ["page-1", "page-2", "page-3", "page-4"];
    return (
        <>
            <img src={imgPrincipalAdmissions} alt="principal-admision" className='imgPrincipal'/>
            <Container className="maxStyle">
                <Row>
                    <Col lg={1}></Col>
                    <Col lg={10}>
                        <p className='paragraph mt-4'>¿Quieres ser parte del Liceo? Mira lo sencillo que es…</p>
                        <Row>
                            {images.map((imageName, index) => (
                                <Col md={6} key={index}>
                                    <img
                                        key={index}
                                        src={require(`../../assets/images/admissions/${imageName}.png`)}
                                        alt={imageName}
                                        className='mb-4'
                                        style={{ margin: '10px', width: "-webkit-fill-available"}}
                                    />
                                </Col>
                            ))}
                            </Row>
                    </Col>
                    <Col lg={1}></Col>
                </Row>
            </Container>
        </>
    );
}

export default Admissions;