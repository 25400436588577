import iconEmail from '../../assets/images/footer/email.png';
import iconPhone from '../../assets/images/footer/phone.png';
import iconContact from '../../assets/images/footer/contact.png';

export const InformationServices = [
    {
        icon: iconEmail,
        text: 'elviristo@yahoo.com'
    },
    {
        icon: iconPhone,
        text: '(+57) 316 73 45 163'
    },
    {
        icon: iconPhone,
        text: '(+57) 322 23 60 574 '
    },
    {
        icon: iconContact,
        text: 'Kra 81A N° 81ª - 82ª - 18 sur Getsemaní '
    },
    {
        icon: iconContact,
        text: 'Lunes a Viernes 6:00am a 2:00pm '
    }
];