import { combineReducers } from 'redux';
import gelleryReducer from './gallery/reducer';
import newReducer from './news/reducer';
import authReducer from './auth/reducer';

const rootReducer = combineReducers({
    gallery: gelleryReducer,
    news: newReducer,
    auth: authReducer
});

export default rootReducer;