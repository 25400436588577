import React, { useState } from 'react';
import imgLogo from '../../assets/images/login/logo.svg';
import './login.css';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../redux/auth/action';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await dispatch(loginAction(email, password));
        if (!response?.data?.data) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Correo electrónico o contraseña incorrectos. Por favor, inténtalo de nuevo.',
            });
        } else {
            navigate("/38ababde-310d-41a6-8044-c3ee5490e0bd/home");
        }

    };

    return (
        <div style={{ marginTop: '50px' }}>
            <div className="login">
                <div className="login-container">
                    <img src={imgLogo} alt="Logo" width="150" height="150" style={{ display: 'block', margin: '0 auto' }} />
                    <h2>Inicio de sesión</h2>
                    <h3>Inicie sesión con tus datos de usuario</h3>
                    <form id="login-form" onSubmit={handleSubmit}>
                        <div className="input-container">
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required />
                        </div>
                        <div className="input-container">
                            <label htmlFor="password">Contraseña:</label>
                            <div className="password-input">
                                <input
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    required
                                />
                                <span className="toggle-password" onClick={togglePassword}>
                                    <FontAwesomeIcon
                                        icon={showPassword ? faEye : faEyeSlash}
                                    />
                                </span>
                            </div>
                        </div>
                        <button type="submit">Iniciar Sesión</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
