import React from 'react';
import './MissionVision.css';
import { Col, Container, Row } from 'react-bootstrap';
import { moralValues, missionAndVision } from './Index.ts';
import imgBandera from '../../assets/images/mission-vision/bandera.png';
import imgEscudo from '../../assets/images/mission-vision/escudo.png';

function MissionVision() {
    

    return (
        <Container className='maxStyle mt-5'>
            <Row>
                {missionAndVision.map(({ icon, className, title, description }, index) => (
                    <Col lg={6} key={index} className={className}>
                        <img src={icon} alt="icon" width={50} className='mb-3'/>
                        <span className='titleMissionVision' style={{marginLeft:'1.25rem'}}>{title}</span>
                        <p className='paragraph'>{description}</p>
                    </Col>
                ))}
            </Row>

            <Row>
                <Col lg={1}></Col>
                <Col lg={10} className="mt-5">
                    <h2 className='titleMissionVision text-center'>Valores</h2>
                    <hr className="my-4" style={{backgroundColor: "#2A2F9F", height: "0.1875rem"}}></hr>
                    <Row>
                        {moralValues.map(({ icon, className, title, description }, index) => (
                            <Col md={6} key={index}>
                                <Row className='mb-5'>
                                    <Col lg={4} className="text-center">
                                        <img src={icon} alt={title} className="mr-3" />
                                    </Col>
                                    <Col lg={8}>
                                        <p className={`${className} titleMoralValues`}>{title}</p>
                                        <p className='paragraphMoralValues paragraph'>{description}</p>
                                    </Col>
                                </Row>
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col lg={1}></Col>
            </Row>

            <Row>
                <Col lg={1}></Col>
                <Col lg={10} className="mt-5">
                    <h2 className='titleMissionVision text-center colorYellowValues'>Filosofía</h2>
                    <hr className="my-4" style={{backgroundColor: "#D3B574", height: "0.1875rem"}}></hr>
                    <Row>
                        <p>
                        La filosofía del LICEO MANUEL ELKIN PATARROYO tiene como punto de partida el
                        pensamiento del doctor MANUEL ELKIN PATARROYO, quien con sus hechos busca llevar
                        a la práctica el valor de la alteridad, superación, ahínco y perseverancia con su
                        incansable vida de científico pretende conservar el mundo haciéndolo sostenible,
                        sirviendo a los demás como lo demostró al encontrar la cura para varias enfermedades
                        y no aceptar ningún lucro. Se concibe el ser humano como una persona amable,
                        responsable, asertiva, prudente, pacifica, con proyección profesional, que respete los
                        valores humanos, que descubra nuevos conocimientos que le permitan solucionar
                        problemas y satisfacer necesidades individuales y sociales.
                        Como entidad educativa se pretende contribuir a mejorar la calidad de vida, ofrecer un
                        servicio de calidad educativa para impactar y transcender en la comunidad. Se educa
                        para ayudar a los individuos que asumen un papel ante la sociedad y frente a la vida;
                        estimulándolo para que se auto construya. Importa que el educando sea hombre de
                        verdad, dueño de sí mismo, un ser que actúe conforme a unos valores éticos, morales,
                        políticos y económicos, consciente de su responsabilidad y de su destino
                        </p>
                    </Row>
                </Col>
                <Col lg={1}></Col>
            </Row>

            <Row>
                <Col lg={1}></Col>
                <Col lg={10} className="mt-5">
                    <h2 className='titleMissionVision text-center'>Emblemas institucionales</h2>
                    <hr className="my-4" style={{backgroundColor: "#2A2F9F", height: "0.1875rem"}}></hr>
                    <Row>
                        <Col md={8}>
                            <p>El escudo tiene en su interior dos líneas diagonales.Está compuesto por los siguientes blasones:</p>
                            <ul>
                                <li style={{color: "#264A80"}}>
                                    <strong>Símbolo de la Ciencia:</strong> 
                                    <p className='colorDefaultValues'>
                                        ubicado en la parte superior izquierda
                                        homenaje a nuestro científico Manuel Elkin Patarroyo y de quien
                                        es el nombre de la institución.
                                    </p>
                                </li>
                                <li style={{color: "#264A80"}}>
                                    <strong>Libro con una Pluma:</strong> 
                                    <p className='colorDefaultValues'>
                                        la lectura es importante porque divierte
                                        y transmite información y en una forma más trascendental
                                        porque la inteligencia humana es una inteligencia lingüística. Solo gracias al lenguaje se
                                        puede; comprender el mundo, inventar grandes cosas, convivir, aclarar los
                                        sentimientos, resolver problemas y planificar. Para que la inteligencia sea viva, flexible,
                                        perspicaz, racional, convincente, se necesita en primer lugar conocer y saber usar
                                        muchas palabras.
                                    </p>
                                </li>
                                <li style={{color: "#264A80"}}><strong>Mano con el Mundo:</strong> 
                                    <p className='colorDefaultValues'>
                                        una visión de lo que pueden hacer las personas para alcanzar lo
                                        que se desea; con dedicación, inteligencia y perseverancia.
                                        Dentro del escudo se encuentran las palabras Ciencia y Educación, Unidas Para una
                                        Colombia Mejor. Es el lema de la institución el cual refleja la educación integral.
                                    </p> 
                                </li>
                            </ul>
                        </Col>
                        <Col md={4} style={{margin:"auto", textAlign: "center"}}>
                            <img src={imgBandera} alt="icon" className='mb-3'/>
                        </Col>
                    </Row>
                </Col>
                <Col lg={1}></Col>
            </Row>
            

            <Row>
                <Col lg={1}></Col>
                <Col lg={10} className="mt-5">
                    <h2 className='titleMissionVision text-center colorYellowValues'>Emblemas institucionales</h2>
                    <hr className="my-4" style={{backgroundColor: "#D3B574", height: "0.1875rem"}}></hr>
                    <Row>
                        <Col md={4}  style={{margin:"auto", textAlign: "center"}}>
                            <img src={imgEscudo} alt="icon" className='mb-3'/>
                        </Col>
                        <Col md={8}>
                            <p>Está compuesta por dos franjas horizontales:</p>
                            <ul>
                                <li className='colorYellowValues'>
                                   <strong className='colorYellowValues'>El color verde: </strong>
                                   <p className='colorDefaultValues'>
                                        La variedad de flora y fauna (biodiversidad)
                                        que es representativa en el país
                                   </p>
                                </li>
                                <li className='colorYellowValues'>
                                    <strong className='colorYellowValues'>El color azul:</strong> 
                                    <p className='colorDefaultValues'>
                                        significa la riqueza de Colombia representada
                                        en la gran cantidad de agua (océanos, mares, ríos, lagunas,
                                        lagos entre otros afluentes) que hay en ella siendo esta la
                                        mayor fuente de vida para los seres vivos. 
                                    </p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Col>
                <Col lg={1}></Col>
            </Row>

            <Row>
                <Col lg={1}></Col>
                <Col lg={10} className="mt-5">
                    <h2 className='titleMissionVision text-center colorYellowValues'>Himnos</h2>
                    <hr className="my-4" style={{backgroundColor: "#D3B574", height: "0.1875rem"}}></hr>
                    <Row>
                        <Col md={12}  style={{margin:"auto", textAlign: "center"}}>
                           <p>
                                El Himno de la institución expresa sentimientos de alegría y celebración, resalta los
                                valores patarroyanos, así como la unión familiar y la espiritualidad.
                           </p>
                           <Row>
                            <Col md={4}></Col>
                            <Col md={3}>
                                    <p>
                                        <strong>CORO <br/></strong>
                                        Patarroyanos, siempre felices,
                                        con humildad en el corazón,
                                        (y) nos contagiamos de alegría,
                                        y amor por la educación.
                                        <br/>
                                        <strong>I  <br/></strong>
                                        Somos formados con exigencia,
                                        y nuestro lema es progresar,
                                        marcando siempre la diferencia,
                                        con paso firme en cualquier lugar.
                                        <br/>
                                        <strong>II <br/></strong>
                                        Soy orgulloso Patarroyano,
                                        representando mi institución,
                                        todos unidos como familia.
                                        donde prima el amor de Dios.
                                        <br/> <br/>
                                        <strong>CORO  <br/></strong>
                                        Patarroyanos, siempre felices,
                                        con humildad en el corazón,
                                        (y) nos contagiamos de alegría,
                                        y amor por la educación.
                                        <br/>
                                        <strong>III <br/></strong>
                                        Ser un líder bien educado,
                                        dignifica nuestro existir
                                        de ahí que siempre estaré luchando,
                                        para disfrutar de un gran país.
                                        <br/>
                                        <strong> IV</strong>
                                        La armonía, aquí construida,
                                        nos permite vivir en paz,
                                        acentuando así los valores,
                                        dejando huella en el andar.
                                    </p>
                            </Col>
                            <Col md={4}></Col>
                           </Row>
                        </Col>
                    </Row>
                </Col>
                <Col lg={1}></Col>
            </Row>

        </Container>
    );
}

export default MissionVision;
