import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as draftToHtml from 'draftjs-to-html';
import { createNew, deleteNew, fetchNews } from '../../redux/news/action';

import './NewsForm.css';

const NewsForm = () => {
  const dispatch = useDispatch();

  const mainImageInputRef = useRef(null);
  const news = useSelector(state => state.news.news["otherNews"] || []);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    image: null,
  });

  const handleChange = (newEditorState) => {
    const contentState = newEditorState.getCurrentContent();
    const contentRaw = convertToRaw(contentState);
    setFormData({
      ...formData,
      description: draftToHtml(contentRaw)
    });
    setEditorState(newEditorState);
  };

  const handleChangeTitle = (e) => {
    setFormData({
      ...formData,
      title: e.target.value
    });
  };

  useEffect(() => {
    dispatch(fetchNews());
  }, [dispatch]);

  const handleMainImageChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0]
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const contentState = editorState.getCurrentContent();
    const contentRaw = convertToRaw(contentState);
    const content = JSON.stringify(contentRaw);

    const token = localStorage.getItem('USER_TOKEN');
    dispatch(createNew({ ...formData, content }, token));
    setTimeout(() => {
      dispatch(fetchNews());
    }, 2000);
    setFormData({
      title: '',
      image: null,
      secondaryImages: []
    });
    setEditorState(EditorState.createEmpty());

    if (mainImageInputRef.current) {
      mainImageInputRef.current.value = '';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('es-ES', options);
  };

  const imageBodyTemplate = (rowData) => {
    return <img src={rowData.image} alt={rowData.name} style={{ width: '100px' }} />;
  };

  const deleteItem = (item) => {
    dispatch(deleteNew(item?.id));
    dispatch(fetchNews());
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <button className="p-button p-component p-button-rounded p-button-danger" onClick={() => deleteItem(rowData)}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    );
  };

  const rowExpansionTemplate = (rowData) => {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: rowData.description }} />
      </div>
    );
  };
  const [expandedRows, setExpandedRows] = useState([]);

  return (
    <div style={{ 'marginTop': '100px' }}>
      <div className="title-container-gallery">
        <h1 className="title-gallery-form ">Noticias</h1>
        <div className="separator--gallery-form "></div>
      </div>
      <div className="container">
        <div className="container-gallery-admin">
          <h2 className="title-gallery-form">Agregar Noticia / Evento</h2>
          <form className="gallery-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="title">Título:</label>
              <input type="text" id="title" name="title" value={formData.title} onChange={handleChangeTitle} required />
            </div>
            <div className="form-group">
              <label htmlFor="content">Contenido:</label>
              <Editor
                editorState={editorState}
                onEditorStateChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="main-image">Imagen Principal:</label>
              <input type="file" id="main-image" ref={mainImageInputRef} name="image" onChange={handleMainImageChange} accept="image/*" required />
            </div>
            <button type="submit">Agregar</button>
          </form>
        </div>
        <div className="container-gallery-admin-table">
          <DataTable value={news} paginator rows={5}
            rowsPerPageOptions={[5, 10, 20]}
            expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate}
          >
            <Column expander header="Contenido" headerClassName="blue-header" />
            <Column field="title" header="Titulo" headerClassName="blue-header" />
            <Column field="image" header="Imagen" body={imageBodyTemplate} headerClassName="blue-header" />
            <Column field="created_at" header="Fecha de creación" body={(rowData) => formatDate(rowData.created_at)} headerClassName="blue-header" />
            <Column header="Acciones" headerClassName="blue-header" body={actionBodyTemplate} />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default NewsForm;